import TableCell, { CellType, CellVariant } from '../../../../components/table/cell';
import { CouponPurchaseType } from '../../../../utility/Datasource.utility';
import _find from 'lodash/find'

const ListConf = {
	rowsPerPageOptions: [50, 100, 150, 200],
	columns: [{
		id: 'redeemed',
		key: 'redeemed',
		sort_key: 'redeemed',
		label: '',
		padding: 'checkbox',
	}, {
		id: 'code',
		key: 'code',
		sort_key: 'code',
		label: 'Codice',
	}, {
		id: 'newspaper.title',
		key: 'newspaper.title',
		sort_key: 'newspaper.title',
		label: 'Testata',
	}, {
		id: 'is_free',
		key: 'is_free',
		sort_key: 'is_free',
		label: 'Tipo',
	}, {
		id: 'customer_email',
		key: 'customer_email',
		sort_key: 'customer_email',
		label: 'Inviato a',
	}, {
		id: 'profile.email',
		key: 'profile.email',
		sort_key: 'profile.email',
		label: 'Riscattato da',
	}, {
		id: 'license_type',
		key: 'license_type',
		sort_key: 'license_type',
		label: 'Tipo di Acquisto'
	}],
	render: {
		formatHead: (_, value) => {
			return (
				<TableCell variant={CellVariant.head} value={value} />
			);
		},
		formatValue: (key, value) => {
			switch (key) {
				case 'code':
					return <TableCell type={CellType.code} value={value} />
				case 'profile.email':
					return <TableCell type={CellType.email} value={value} />
				case 'redeemed':
					return <TableCell type={CellType.active} value={value} />
				case 'is_free':
					return <TableCell type={CellType.default} value={value === true ? 'Gratis' : 'Pagamento'} />
				case 'license_type':
					return <TableCell type={CellType.default} value={_find(CouponPurchaseType,(i) => i.id === value)?.label} />
				default:
					return <TableCell type={CellType.default} value={value} />
			}
		},
	},
};


export const module = {
	...ListConf
}
